// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-participation-tsx": () => import("./../../../src/pages/participation.tsx" /* webpackChunkName: "component---src-pages-participation-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-standard-tsx": () => import("./../../../src/pages/standard.tsx" /* webpackChunkName: "component---src-pages-standard-tsx" */),
  "component---src-pages-toc-tsx": () => import("./../../../src/pages/toc.tsx" /* webpackChunkName: "component---src-pages-toc-tsx" */)
}

